import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import LoadingSpinner from "../components/loading-spinner";
import SearchLinks from "../components/search/search-links";
import SearchGrid from "../components/search/search-grid";
import algoliasearch from "algoliasearch";
import { Search as Constants, Algolia } from "../helpers/constants";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { PageProps } from "gatsby";

type PageLocationInputProps = {
  query: string;
  results: [];
  refs: [];
  focusedIndex: number;
  visible: boolean;
};

type SearchProps = PageProps<unknown, unknown, PageLocationInputProps>;

const Search = (props: SearchProps): JSX.Element => {
  const PathObj = GetSimplePathObj(Constants.PathObjName);
  const [State, SetState] = React.useState({
    response: { hits: [] },
    loading: true,
  });

  /* Results must be searched/returned/loaded on page-load */
  React.useEffect(() => {
    const Client = algoliasearch(Algolia.AppId, Algolia.ApiKey);
    const Index = Client.initIndex(Algolia.IndexName);

    Index.search(props.location.state.query).then((response) => {
      SetState({ response: response, loading: false });
    });
  }, [props.location.state]);

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <SearchLinks
        query={props.location.state?.query}
        results={State.response.hits}
        loading={State.loading}
      />
      <SearchGrid results={State.response.hits} loading={State.loading} />
      <LoadingSpinner open={State.loading} />
    </Layout>
  );
};

export default Search;
