import React from "react";
import LaunchIcon from "@material-ui/icons/Launch";
import { Search as Constants } from "../../helpers/constants";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { GQLProduct } from "../../types";
import { Link } from "gatsby";

const UseStyles = makeStyles({
  linkHeader: {
    width: "auto",
    color: "white",
    backgroundColor: "#00958D",
    padding: ".35rem 0 .35rem 1.75rem",
    marginTop: ".85rem",
  },

  links: {
    padding: ".35rem .5rem .35rem .5rem",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "& a": {
      color: "black",

      "& svg": {
        height: "1rem",
      },
    },
  },

  show: {
    display: "block",
  },

  hide: {
    display: "none",
  },
});

type SearchLinksProps = {
  loading: boolean;
  results: GQLProduct[];
  query: string;
};

/*
 * query -> Search query
 * results -> Results of the search
 * loading -> True if ths search page is loading
 */
const SearchLinks = (props: SearchLinksProps): JSX.Element => {
  const Classes = UseStyles();
  const HasResults =
    !props.loading && props.results && props.results?.length > 0;

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Typography variant="body2">
        {Constants.ShowingResultsText}
        <b> &apos;{props.query}&apos;</b>
      </Typography>
      <div className={HasResults ? Classes.show : Classes.hide}>
        <Typography variant="h6" className={Classes.linkHeader}>
          {Constants.QuickLinksText}
        </Typography>
        {props.results?.map((node, index) => (
          <Typography variant="subtitle1" key={index} className={Classes.links}>
            <Link to={Constants.ProductsPath + node.path}>
              <LaunchIcon />
              {node.name}
            </Link>
          </Typography>
        ))}
      </div>
    </Grid>
  );
};

export default SearchLinks;
